.footer-container {
  width: 100%;
  position: relative;
  margin-top: 50px;
}

.footer-container .footer-bg-img {
  background-image: url("/images/footer-background.png");
  width: 100%;
  height: 600px;
  position: absolute;
  left: 0;
  z-index: -10;
}

.footer-container .footer-block {
  background-color: #fff;
  display: flex;
  width: 85%;
  margin: 0 auto;
  border: 2px solid #b80800;
  position: relative;
  top: -40px;
  padding: 20px;
}

.footer-container .footer-block div {
  flex: 1;
  border-right: 2px solid #96cb59;
}

.footer-block img {
  width: 20vw;
  max-width: 300px;
  padding: 10px 0 20px 0;
}

.footer-block p {
  color: #888a8d;
}

.footer-block ul {
  margin: 0 auto;
  width: 80%;
  list-style: none;
}

.footer-block ul li {
  padding: 5px;
  font-size: 0.9rem;
}

.footer-block ul li a {
  color: #b80800;
}

.footer-block ul li a:hover {
  color: #96cb59;
}

.footer-block ul li span {
  color: #b80800;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
}

.footer-block ul > span {
  color: #888a8d;
  padding: 5px;
  vertical-align: bottom;
  font-size: 0.9rem;
}

.footer-block .foot-btn-cont {
  width: fit-content;
  margin: 0 auto;
}

.footer-block .foot-btn-cont button:first-child {
  margin-top: 5px;
}

.footer-block .foot-btn-cont button {
  text-transform: uppercase;
  display: block;
  color: #fff;
  padding: 10px;
  font-size: 1.2rem;
  width: 18vw;
  max-width: 200px;
  margin: 20px 0;
  border-radius: 20px;
  border: none;
}

.footer-block .social-icons svg {
  fill: #888a8d;
}

.footer-block .social-icons .social-cont {
  border-radius: 50%;
  background-color: #888a8d;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 10px 5px;
}

.footer-block .social-icons .social-cont svg {
  fill: #fff;
  padding: 5px;
}

.footer-block .foot-btn-cont .book {
  background-color: #96cb59;
}

.footer-block .foot-btn-cont .book:hover {
  background-color: #b80800;
}

.footer-block .foot-btn-cont .join {
  background-color: #b80800;
}

.footer-block .foot-btn-cont .co-browse {
  background-color: #595a5c;
}
.footer-block .foot-btn-cont .join:hover {
  background-color: #96cb59;
}

.footer-container .footer-block div:last-child {
  border-right: none;
}

.footer-subtext {
  text-align: center;
  width: 85%;
  margin: 0 auto;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .footer-container .footer-block {
    width: 95%;
  }
  .footer-container .footer-bg-img {
    height: 500px;
    bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .footer-container .footer-bg-img {
    height: 1000px;
  }
  .footer-block .foot-btn-cont button,
  .footer-block img {
    width: 250px;
  }
  .footer-container .footer-block {
    flex-direction: column;
    text-align: center;
  }
  .footer-container .footer-block div {
    border-bottom: 2px solid #96cb59;
    border-right: none;
    padding: 20px 0;
  }
  .footer-container .footer-block div:first-child {
    padding-top: 0;
  }
  .footer-container .footer-block div:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}
