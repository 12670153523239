/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

.footer-container {
  width: 100%;
  position: relative;
  margin-top: 50px;
}

.footer-container .footer-bg-img {
  background-image: url("/images/footer-background.png");
  width: 100%;
  height: 600px;
  position: absolute;
  left: 0;
  z-index: -10;
}

.footer-container .footer-block {
  background-color: #fff;
  display: flex;
  width: 85%;
  margin: 0 auto;
  border: 2px solid #b80800;
  position: relative;
  top: -40px;
  padding: 20px;
}

.footer-container .footer-block div {
  flex: 1 1;
  border-right: 2px solid #96cb59;
}

.footer-block img {
  width: 20vw;
  max-width: 300px;
  padding: 10px 0 20px 0;
}

.footer-block p {
  color: #888a8d;
}

.footer-block ul {
  margin: 0 auto;
  width: 80%;
  list-style: none;
}

.footer-block ul li {
  padding: 5px;
  font-size: 0.9rem;
}

.footer-block ul li a {
  color: #b80800;
}

.footer-block ul li a:hover {
  color: #96cb59;
}

.footer-block ul li span {
  color: #b80800;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
}

.footer-block ul > span {
  color: #888a8d;
  padding: 5px;
  vertical-align: bottom;
  font-size: 0.9rem;
}

.footer-block .foot-btn-cont {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.footer-block .foot-btn-cont button:first-child {
  margin-top: 5px;
}

.footer-block .foot-btn-cont button {
  text-transform: uppercase;
  display: block;
  color: #fff;
  padding: 10px;
  font-size: 1.2rem;
  width: 18vw;
  max-width: 200px;
  margin: 20px 0;
  border-radius: 20px;
  border: none;
}

.footer-block .social-icons svg {
  fill: #888a8d;
}

.footer-block .social-icons .social-cont {
  border-radius: 50%;
  background-color: #888a8d;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 10px 5px;
}

.footer-block .social-icons .social-cont svg {
  fill: #fff;
  padding: 5px;
}

.footer-block .foot-btn-cont .book {
  background-color: #96cb59;
}

.footer-block .foot-btn-cont .book:hover {
  background-color: #b80800;
}

.footer-block .foot-btn-cont .join {
  background-color: #b80800;
}

.footer-block .foot-btn-cont .co-browse {
  background-color: #595a5c;
}
.footer-block .foot-btn-cont .join:hover {
  background-color: #96cb59;
}

.footer-container .footer-block div:last-child {
  border-right: none;
}

.footer-subtext {
  text-align: center;
  width: 85%;
  margin: 0 auto;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .footer-container .footer-block {
    width: 95%;
  }
  .footer-container .footer-bg-img {
    height: 500px;
    bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .footer-container .footer-bg-img {
    height: 1000px;
  }
  .footer-block .foot-btn-cont button,
  .footer-block img {
    width: 250px;
  }
  .footer-container .footer-block {
    flex-direction: column;
    text-align: center;
  }
  .footer-container .footer-block div {
    border-bottom: 2px solid #96cb59;
    border-right: none;
    padding: 20px 0;
  }
  .footer-container .footer-block div:first-child {
    padding-top: 0;
  }
  .footer-container .footer-block div:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.header {
  z-index: 10;
  position: relative;
}

.header .top-bar .nav-item {
  color: #fff;
}

.header .bot-bar .nav .nav-item {
  color: #fff;
}

.top-bar {
  width: 100%;
  display: inline-flex;
  background-color: #b80800;
}

.top-bar .nav-item {
  text-transform: uppercase;
}

.top-bar .left-top-bar {
  background-color: #b80800;
  flex: 4 1;
  width: 120%;
  text-align: right;
  padding: 7px;
}

.top-bar .left-top-bar .nav-item {
  padding: 0 25px;
  cursor: pointer;
}

.top-bar .right-top-bar {
  background-color: #96cb59;
  flex: 1 1;
  text-align: center;
  -webkit-clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%);
}

.right-top-bar * {
  display: inline-block;
  width: 100%;
  padding: 7px;
}

.bot-bar {
  display: flex;
  /* background: linear-gradient(black, transparent); */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.bot-bar.account-bg-menu{
  background: #414042;
}

.bot-bar .logo {
  padding: 20px;
}

.bot-bar .logo,
.bot-bar .button-cont {
  flex: 1 1;
  text-align: center;
}

.bot-bar .nav {
  flex: 3 1;
  margin-top: 20px;
}

.bot-bar .button-cont button {
  padding: 10px 30px;
  background-color: #b80800;
  color: #fff;
  margin-top: 15px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

.bot-bar .button-cont button:hover {
  background: #96cb59;
}

.nav .nav-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  text-align: center;
}

.nav .nav-list .nav-item {
  flex: 1 1;
}

.burger {
  display: none;
  flex-direction: column;
  gap: 5px;
}

.burger span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #fff;
}

.booking-Pop-cont,
.login-Pop-cont {
  position: absolute;
  top: 500px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 500px;
  text-align: center;
  z-index: 1000;
}

.footer-container .booking-Pop-cont{
  top: -500px;
}

.booking-Pop-cont {
  width: 96%;
  max-width: 1000px;
  border-radius: 10px;
}

.login-Pop-cont form {
  text-align: center;
}

.booking-Pop-cont .close,
.login-Pop-cont .close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background-color: #b80800;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
}

.login-Pop-cont .policies a {
  margin: 10px;
}

.login-Pop-cont .policies {
  margin-top: 10px;
  font-size: 0.8rem;
  color: #333;
}

.booking-Pop-cont h2,
.login-Pop-cont h2 {
  color: #b80800;
}

.login-Pop-cont form input {
  width: 100%;
  background-color: #fff;
  border: 1px solid grey;
  height: 30px;
  border-radius: 15px;
  color: #333;
  padding: 0 15px;
}

.login-Pop-cont form input[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin: 0 10px;
  position: relative;
  top: 5px;
}
.login-Pop-cont form label {
  color: #333;
}

.login-Pop-cont form a {
  color: #333;
  text-decoration: underline;
}
.login-Pop-cont form div {
  margin: 10px 0;
}
.login-Pop-cont form button {
  width: 100%;
  background-color: #96cb59;
}

.remember-me-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  cursor: pointer;
}

.booking-Pop-cont .booking-card-cont {
  padding: 15px 0 0 0;
}

.booking-Pop-cont .booking-card-cont .booking-card {
  width: 25%;
  display: inline-block;
  padding: 10px;
}

.booking-Pop-cont .booking-card-cont .booking-card img {
  width: 100%;
  border-radius: 5px;
}

.booking-Pop-cont .booking-card-cont .booking-card .iao-overlay-cont {
  position: relative;
}

.booking-Pop-cont .booking-card-cont .booking-card .iao-overlay {
  text-align: center;
  position: absolute;
  border-radius: 5px;
  width: 100%; /* Full width (cover the whole page) */
  height: 97%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
}

.booking-Pop-cont .booking-card-cont .booking-card .iao-overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
  color: #fff; 
}

.booking-Pop-cont .booking-card-cont .booking-card p {
  color: #96cb59;
  padding: 10px;
}

.booking-Pop-cont .booking-card-cont .booking-card.card-overlay img {
  position: relative;
}

.booking-Pop-cont .booking-card-cont .booking-card.card-overlay img:after {
  content: "";
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: 0.7;
}

.booking-Pop-cont .booking-card-cont .booking-card.card-overlay p {
  color: #333;
}
@media screen and (max-width: 768px) {
  .booking-Pop-cont {
    top: 0;
    transform: translate(-50%, 5%);
    overflow: scroll;
  }

  .nav {
    display: none;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    position: absolute;
    top: 15px;
    left: 0;
    background-color: #333;
  }
  .booking-Pop-cont .booking-card-cont .booking-card {
    width: 50%;
    /* vertical-align: middle; */
    vertical-align: text-top;
  }
  .booking-Pop-cont .booking-card-cont .booking-card p {
    min-height: 60px;
  }
  .booking-Pop-cont,
  .login-Pop-cont {
    width: 90%;
  }

  .nav.active {
    display: flex;
  }

  .nav.active .nav-list {
    flex-direction: column;
  }

  .nav.active .nav-list .nav-item {
    padding: 20px;
  }

  .top-bar .right-top-bar {
    flex: 2 1;
  }

  .burger {
    justify-content: space-around;
    margin-top: 15px;
    margin-right: 15px;
    max-height: 35px;
    display: flex;
    z-index: 100;
    background-color: transparent;
    border: none;
  }

  .bot-bar .button-cont button {
    padding: 10px 20px;
    background-color: #b80800;
    margin-top: 15px;
    border-radius: 30px;
    border: none;
  }
}

@media screen and (max-width: 480px) {
  .top-bar .left-top-bar .nav-item {
    padding: 0 15px;
  }
  .top-bar .left-top-bar {
    text-align: center;
  }
  .bot-bar .logo{
    padding: 20px 0 20px 20px;
  }
  .bot-bar .logo a img{
    max-width: 150px;
  }
}

@media screen and (max-width: 340px) {
  .top-bar .left-top-bar .nav-item {
    padding: 0 7px;
  }
}

.remember-me-wrapper-flex {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.remember-me-wrapper-flex .remember-1 {
  padding: 8px;
}
.remember-me-wrapper-flex .remember-1 {
  padding: 8px;
}

.btn-login {
  box-shadow: 3px 3px 9px black;
}

@font-face {
  font-family: "robotomedium";
  src: url("/fonts/roboto-medium-webfont.woff2") format("woff2"),
    url("/fonts/roboto-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotocondensed";
  src: url("/fonts/roboto-condensed-webfont.woff2") format("woff2"),
    url("/fonts/roboto-condensed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "arialregular";
  src: url("/fonts/arial-webfont.woff2") format("woff2"),
    url("/fonts/arial-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "arialbold";
  src: url("/fonts/arialbd-webfont.woff2") format("woff2"),
    url("/fonts/arialbd-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "futura_stdheavy";
  src: url("/fonts/futurastd-heavy-webfont.woff2") format("woff2"),
    url("/fonts/futurastd-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "minion_proregular";
  src: url("/fonts/minionpro-regular-webfont.woff2") format("woff2"),
    url("/fonts/minionpro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  margin: 0;
}

.proj-cont {
  overflow-x: clip;
}

body {
  font-family: "arialregular";
  color: rgb(var(--foreground-rgb));
  background: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "robotomedium";
}

a {
  color: inherit;
  text-decoration: none;
}

.MuiCard-root {
  height: 500px !important;
  border-radius: 10px !important;
}

.MuiGrid-item:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
  z-index: 5;
  margin: 0 10px;
}

.MuiGrid-item:hover {
  flex-basis: 23%;
}

.activeLink a {
  color: #96cb59;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

main {
  min-height: 300px;
}

.btn {
  padding: 5px 50px;
  color: #fff;
  border-radius: 15px;
  border: none;
  background-color: #96cb59;
  margin: 5px;

  text-transform: uppercase;
  font-weight: bold;

  cursor: pointer;
}

.btn:hover {
  background-color: #b80800;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.bot-bar .button-cont button {
  text-transform: uppercase !important;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .MuiGrid-item:hover {
    transform: none;
  }
  
  .MuiGrid-item:hover {
    flex-basis: 25%;
  }
}
