@font-face {
  font-family: "robotomedium";
  src: url("/fonts/roboto-medium-webfont.woff2") format("woff2"),
    url("/fonts/roboto-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotocondensed";
  src: url("/fonts/roboto-condensed-webfont.woff2") format("woff2"),
    url("/fonts/roboto-condensed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "arialregular";
  src: url("/fonts/arial-webfont.woff2") format("woff2"),
    url("/fonts/arial-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "arialbold";
  src: url("/fonts/arialbd-webfont.woff2") format("woff2"),
    url("/fonts/arialbd-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "futura_stdheavy";
  src: url("/fonts/futurastd-heavy-webfont.woff2") format("woff2"),
    url("/fonts/futurastd-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "minion_proregular";
  src: url("/fonts/minionpro-regular-webfont.woff2") format("woff2"),
    url("/fonts/minionpro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  margin: 0;
}

.proj-cont {
  overflow-x: clip;
}

body {
  font-family: "arialregular";
  color: rgb(var(--foreground-rgb));
  background: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "robotomedium";
}

a {
  color: inherit;
  text-decoration: none;
}

.MuiCard-root {
  height: 500px !important;
  border-radius: 10px !important;
}

.MuiGrid-item:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
  z-index: 5;
  margin: 0 10px;
}

.MuiGrid-item:hover {
  flex-basis: 23%;
}

.activeLink a {
  color: #96cb59;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

main {
  min-height: 300px;
}

.btn {
  padding: 5px 50px;
  color: #fff;
  border-radius: 15px;
  border: none;
  background-color: #96cb59;
  margin: 5px;

  text-transform: uppercase;
  font-weight: bold;

  cursor: pointer;
}

.btn:hover {
  background-color: #b80800;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.bot-bar .button-cont button {
  text-transform: uppercase !important;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .MuiGrid-item:hover {
    transform: none;
  }
  
  .MuiGrid-item:hover {
    flex-basis: 25%;
  }
}