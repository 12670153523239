.header {
  z-index: 10;
  position: relative;
}

.header .top-bar .nav-item {
  color: #fff;
}

.header .bot-bar .nav .nav-item {
  color: #fff;
}

.top-bar {
  width: 100%;
  display: inline-flex;
  background-color: #b80800;
}

.top-bar .nav-item {
  text-transform: uppercase;
}

.top-bar .left-top-bar {
  background-color: #b80800;
  flex: 4;
  width: 120%;
  text-align: right;
  padding: 7px;
}

.top-bar .left-top-bar .nav-item {
  padding: 0 25px;
  cursor: pointer;
}

.top-bar .right-top-bar {
  background-color: #96cb59;
  flex: 1;
  text-align: center;
  clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%);
}

.right-top-bar * {
  display: inline-block;
  width: 100%;
  padding: 7px;
}

.bot-bar {
  display: flex;
  /* background: linear-gradient(black, transparent); */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.bot-bar.account-bg-menu{
  background: #414042;
}

.bot-bar .logo {
  padding: 20px;
}

.bot-bar .logo,
.bot-bar .button-cont {
  flex: 1;
  text-align: center;
}

.bot-bar .nav {
  flex: 3;
  margin-top: 20px;
}

.bot-bar .button-cont button {
  padding: 10px 30px;
  background-color: #b80800;
  color: #fff;
  margin-top: 15px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

.bot-bar .button-cont button:hover {
  background: #96cb59;
}

.nav .nav-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  text-align: center;
}

.nav .nav-list .nav-item {
  flex: 1;
}

.burger {
  display: none;
  flex-direction: column;
  gap: 5px;
}

.burger span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #fff;
}

.booking-Pop-cont,
.login-Pop-cont {
  position: absolute;
  top: 500px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 500px;
  text-align: center;
  z-index: 1000;
}

.footer-container .booking-Pop-cont{
  top: -500px;
}

.booking-Pop-cont {
  width: 96%;
  max-width: 1000px;
  border-radius: 10px;
}

.login-Pop-cont form {
  text-align: center;
}

.booking-Pop-cont .close,
.login-Pop-cont .close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background-color: #b80800;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
}

.login-Pop-cont .policies a {
  margin: 10px;
}

.login-Pop-cont .policies {
  margin-top: 10px;
  font-size: 0.8rem;
  color: #333;
}

.booking-Pop-cont h2,
.login-Pop-cont h2 {
  color: #b80800;
}

.login-Pop-cont form input {
  width: 100%;
  background-color: #fff;
  border: 1px solid grey;
  height: 30px;
  border-radius: 15px;
  color: #333;
  padding: 0 15px;
}

.login-Pop-cont form input[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin: 0 10px;
  position: relative;
  top: 5px;
}
.login-Pop-cont form label {
  color: #333;
}

.login-Pop-cont form a {
  color: #333;
  text-decoration: underline;
}
.login-Pop-cont form div {
  margin: 10px 0;
}
.login-Pop-cont form button {
  width: 100%;
  background-color: #96cb59;
}

.remember-me-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  cursor: pointer;
}

.booking-Pop-cont .booking-card-cont {
  padding: 15px 0 0 0;
}

.booking-Pop-cont .booking-card-cont .booking-card {
  width: 25%;
  display: inline-block;
  padding: 10px;
}

.booking-Pop-cont .booking-card-cont .booking-card img {
  width: 100%;
  border-radius: 5px;
}

.booking-Pop-cont .booking-card-cont .booking-card .iao-overlay-cont {
  position: relative;
}

.booking-Pop-cont .booking-card-cont .booking-card .iao-overlay {
  text-align: center;
  position: absolute;
  border-radius: 5px;
  width: 100%; /* Full width (cover the whole page) */
  height: 97%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
}

.booking-Pop-cont .booking-card-cont .booking-card .iao-overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
  color: #fff; 
}

.booking-Pop-cont .booking-card-cont .booking-card p {
  color: #96cb59;
  padding: 10px;
}

.booking-Pop-cont .booking-card-cont .booking-card.card-overlay img {
  position: relative;
}

.booking-Pop-cont .booking-card-cont .booking-card.card-overlay img:after {
  content: "";
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: 0.7;
}

.booking-Pop-cont .booking-card-cont .booking-card.card-overlay p {
  color: #333;
}
@media screen and (max-width: 768px) {
  .booking-Pop-cont {
    top: 0;
    transform: translate(-50%, 5%);
    overflow: scroll;
  }

  .nav {
    display: none;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    position: absolute;
    top: 15px;
    left: 0;
    background-color: #333;
  }
  .booking-Pop-cont .booking-card-cont .booking-card {
    width: 50%;
    /* vertical-align: middle; */
    vertical-align: text-top;
  }
  .booking-Pop-cont .booking-card-cont .booking-card p {
    min-height: 60px;
  }
  .booking-Pop-cont,
  .login-Pop-cont {
    width: 90%;
  }

  .nav.active {
    display: flex;
  }

  .nav.active .nav-list {
    flex-direction: column;
  }

  .nav.active .nav-list .nav-item {
    padding: 20px;
  }

  .top-bar .right-top-bar {
    flex: 2 1;
  }

  .burger {
    justify-content: space-around;
    margin-top: 15px;
    margin-right: 15px;
    max-height: 35px;
    display: flex;
    z-index: 100;
    background-color: transparent;
    border: none;
  }

  .bot-bar .button-cont button {
    padding: 10px 20px;
    background-color: #b80800;
    margin-top: 15px;
    border-radius: 30px;
    border: none;
  }
}

@media screen and (max-width: 480px) {
  .top-bar .left-top-bar .nav-item {
    padding: 0 15px;
  }
  .top-bar .left-top-bar {
    text-align: center;
  }
  .bot-bar .logo{
    padding: 20px 0 20px 20px;
  }
  .bot-bar .logo a img{
    max-width: 150px;
  }
}

@media screen and (max-width: 340px) {
  .top-bar .left-top-bar .nav-item {
    padding: 0 7px;
  }
}

.remember-me-wrapper-flex {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.remember-me-wrapper-flex .remember-1 {
  padding: 8px;
}
.remember-me-wrapper-flex .remember-1 {
  padding: 8px;
}

.btn-login {
  box-shadow: 3px 3px 9px black;
}
